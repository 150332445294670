import { Card, Input, List, Modal, Space, Tag, Empty } from 'antd';
import React, { useEffect, useState } from 'react';
import promoCodeService from '../../services/affiliate/promo_code.service';
import { useDispatch, useSelector } from 'react-redux';
import { SearchOutlined } from '@ant-design/icons';

const MasterPromoCodeList = ({ modal, selected_promotion_code_id, onSelectPromoCode, }) => {
    const authRedux = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const [timer, setTimer] = useState()
    const [state, setState] = useState({
        loading : false,
        promotion_codes : [],
    })

    const [filter, setFilter] = useState({
        search : null,
    })

    const doneTyping = async (input) => {
        clearTimeout(timer);
        const newTimer = setTimeout(async () => {
            // check existing
            setFilter(filter => ({...filter, search : input, }))
        }, 300);
        setTimer(newTimer);
    }

    const fetchPromotionCodesByAffiliate = async () => {
        setState(state => ({...state, loading : true, }));
        const { search, } = filter;
        const promotionCodeByAffiliate = await promoCodeService?.getPromoCodeByAffiliate({
            search,
        });

        const { promotion_codes } = promotionCodeByAffiliate;
        const default_promotion_code_id = authRedux?.selected_promotion_code?.promotion_code_id;
        setState(state => ({...state, loading : false, promotion_codes : promotion_codes, selected_promotion_code_id : default_promotion_code_id, }));
    }

    const onPromoCodeChange = async (promotion_code_id) => {
        onSelectPromoCode(promotion_code_id);
    }

    useEffect(() => {
        if(modal){
            fetchPromotionCodesByAffiliate();
        }
    }, [modal, filter])

    return (
        <>
            <div>
                <Input
                placeholder='Search with promotion code'
                prefix={<SearchOutlined />}
                allowClear
                onChange={(e) => doneTyping(e?.target?.value)}
                />
            </div>
            <div style={{ marginTop : 12, }}>
                {
                    state?.promotion_codes?.length > 0 ?
                    (
                        <>
                            <Space direction='vertical' size={12} style={{width : '100%'}}>
                            {
                                state?.promotion_codes?.map((promotion_code, index) => {
                                    const { promotion_code_id, name, parks, promo_code, } = promotion_code;
                                    const is_selected = selected_promotion_code_id === promotion_code_id;
                                    return (
                                        <div key={index} onClick={() => onPromoCodeChange(promotion_code_id)}>
                                            <Card
                                            className={`selection-card ${is_selected ? 'selected' : ''}`}
                                            style={{border:'none', cursor:'pointer'}}
                                            bodyStyle={{padding:12,}}
                                            >
                                                <div>
                                                    <div>
                                                        <Tag style={{border:'none'}} color='orange'>
                                                            <span>{promo_code}</span>
                                                        </Tag>
                                                    </div>
                                                    <div>
                                                        <span style={{color : 'var(--secondary-text-color)', fontSize:12,}}>{parks?.name}</span>
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    )
                                })
                            }
                            </Space>
                        </>
                    )
                    :
                    (
                        <>
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        </>
                    )
                }
            </div>
            {/* <List
            className='promotion_code_list'
            dataSource={state?.promotion_codes}
            renderItem={(item, index) => {
                const { promotion_code_id, name, parks, } = item;
                return (
                    <List.Item
                    onClick={() => onPromoCodeChange(promotion_code_id)}
                    className='promotion_code_list_item' key={index}
                    >
                        <div>
                            <div>
                                <Tag style={{border:'none'}} color='orange'>
                                    <span>{name}</span>
                                </Tag>
                            </div>
                            <div>
                                <span style={{color : 'var(--secondary-text-color)', fontSize:12,}}>{parks?.name}</span>
                            </div>
                        </div>
                    </List.Item>
                )
            }}
            /> */}
        </>
    );
}

export default MasterPromoCodeList;